import React, { useEffect } from "react";
import { Flex, Heading, Image, Link, Button, useColorModeValue } from "@chakra-ui/react";

const MainPage = () => {
  useEffect(() => {
    // Dynamically adding the Benchmark Email Signup form script
    const script = document.createElement("script");
    script.src = "https://lb.benchmarkemail.com//code/lbformnew.js?mFcQnoBFKMTViH%252BG64pOOEitbgbM6uK5c3ABc%252BY3n3LKwT44US0d8w%253D%253D";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);
  const buttonColor = useColorModeValue("#d791a6", "#d791a6");
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      minHeight="100vh"
      color="pink.900"
      p={8}
    >
      {/* Debut Novel Promotion */}
      <Flex direction="column" align="center" mb={16}>
        <Heading as="h2" size="xl" mb={6}>
          Check Out My Debut Novel
        </Heading>
        {/* Preorder and Goodreads Links */}
        <Flex gap={4}>
          <Button as={Link} href="https://books2read.com/u/3LJZ1X"
            bgColor={buttonColor}
            fontWeight="bold"
            fontSize="lg"
            color="white"
            borderRadius="md"
            _hover={{ bg: "#824786", textDecor: "none", color: "white"}}
            _focus={{ outline: "none", boxShadow: "outline" }} isExternal>
            Preorder Now
          </Button>
          <Button as={Link} href="https://www.goodreads.com/book/show/215992664-love-or-tequila-in-unexpected-places"   bgColor={buttonColor}
            fontWeight="bold"
            fontSize="lg"
            color="white"
            borderRadius="md"
            _hover={{ bg: "#824786", textDecor: "none", color: "white"}}
            _focus={{ outline: "none", boxShadow: "outline" }}  isExternal>
            View on Goodreads
          </Button>
        </Flex>
          {/* Full Book Graphic */}
          <Image
          src="bookgraphic.png"
          alt="Full Book Graphic"
          mb={4}
          maxW="500px"
        />
      </Flex>
    </Flex>
  );
};

export default MainPage;
