import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Link,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

const Shop = () => {


  // Define an array of objects with book data
  const books = [
  
    {
      title: "Love or Tequila in Unexpected Places",
      link: "https://books2read.com/u/3LJZ1X",
      imageUrl: "/book1cover.png",
    },
    // Add more books as needed
  ];

  return (
    <Box py={10} >
      <Container
        maxW="container.xl"
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Heading as="h1" size="xl" mb={6} color="lavender">
          Shop
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={10}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          
        >
          {/* Render each book as a shop item */}
          {books.map((book, index) => (
            <ShopItem
              key={index}
              title={book.title}
              link={book.link}
              imageUrl={book.imageUrl}
            />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

const ShopItem = ({ title, link, imageUrl }) => {
  const containerBgColor = useColorModeValue("rgba(255, 228, 245, 0.8)", "rgba(255, 182, 193, 0.8)");
  return (
    <Box
      bg={containerBgColor}
      p={6}
      border="2px solid"
      borderRadius="lg"
      borderColor="pink.200"  
      shadow="xl"
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      maxWidth="500px"
    
    
    >
      <Box
        bg={`url(${imageUrl})`}
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        w="200px"
        h="300px"
        borderRadius="md"
        borderColor="pastel.pink"
        boxShadow="lg"
        mb={4}
      />
      <Heading as="h2" size="lg" mb={4} color="#824786" textAlign="center">
        {title}
      </Heading>
      <Link href={link} isExternal color="#824786" target="_blank" _hover={{color: "#824786", textDecor: "underline"}}>
        <Text fontSize="lg" fontWeight="bold">
          Shop Now
        </Text>
      </Link>
    </Box>
  );
};

export default Shop;
