import React from "react";
import {
  Box,
  Heading,
} from "@chakra-ui/react";

const NewsletterSignup = () => {

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      justifyContent="center"
      p={6}
    >
      <Heading as="h1" size="xl" color="pastel.pink">
        Newsletter Sign-up
      </Heading>
      <Box>
        <iframe
          src="https://lb.benchmarkemail.com//listbuilder/signupnew?IkfHTmyPVq%252Fw%252BHadGIDmhP5pwVnAjsSIvZMp03UItbztO5iNRn8gS049TyW7spdJ"
          width="100%"
          height="900px"
          style={{ border: "none" }}
          title="Newsletter Signup"
        />
      </Box>
        
    </Box>
  );
};

export default NewsletterSignup;
