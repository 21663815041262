// src/components/reviews.js
import React from "react";
import { Box, Heading, SimpleGrid, Image } from "@chakra-ui/react";

const Reviews = () => {
  return (
    <Box
      p={4}
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading as="h1" size="xl" mb={10}>
        Reviews
      </Heading>

      <Heading as="h2" size="lg" mb={5}>
        The Gates of Hell - Book 0.5
      </Heading>
      <ReviewsGrid reviews={book1Reviews} />

      <Heading as="h2" size="lg" mt={10} mb={5}>
        The River of Fire - Book 1
      </Heading>
      <ReviewsGrid reviews={book2Reviews} />
    </Box>
  );
};

const ReviewsGrid = ({ reviews }) => {
  return (
    <SimpleGrid
      columns={{ base: 1, md: 3 }}
      spacing={10}
      mb={10}
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      {reviews.map((review, index) => (
        <Review key={index} review={review} />
      ))}
    </SimpleGrid>
  );
};

const Review = ({ review }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Image
        src={review.image}
        alt={review.name}
        borderRadius="md"
        width="600px"
      />
    </Box>
  );
};

const book1Reviews = [
  {
    name: "Review 1",
    image: "/65.png",
  },
  {
    name: "Review 2",
    image: "/66.png",
  },
  {
    name: "Review 3",
    image: "/67.png",
  },
  {
    name: "Review 4",
    image: "/158.png",
  },
  {
    name: "Review 5",
    image: "/159.png",
  },
  {
    name: "Review 6",
    image: "/160.png",
  },
  {
    name: "Review 7",
    image: "/161.png",
  },
  // Add more reviews for Book 1
];

const book2Reviews = [
  {
    name: "Review 4",
    image: "/64.png",
  },

  // Add more reviews for Book 2
];

export default Reviews;
