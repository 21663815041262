import React from "react";
import {
  Box,
  Text,
  Image,
  Flex,
  Heading,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";

const testimonialsData = [
  {
    testimonial: "This book cover is absolutely amazing!",
    name: "Jane Doe",
    image: "/cover1.jpg",
  },
  {
    testimonial: "The custom artwork exceeded my expectations. It's a masterpiece!",
    name: "John Smith",
    image: "/cover2.jpg",
  },
  // Add more testimonials as needed
];

const Testimonials = () => {
  const containerBgColor = useColorModeValue("rgba(255, 228, 245, 0.8)", "rgba(255, 182, 193, 0.8)");
  const textColor = useColorModeValue("pink.900", "white");
  const borderColor = useColorModeValue("pink.200", "pink.700");

  return (
    <Box py={10} px={4} maxW="container.xl" mx="auto">
      <Heading as="h2" size="xl" mb={8} textAlign="center" color="pastel.pink">
        Testimonials
      </Heading>
      <Stack spacing={8}>
        {testimonialsData.map((testimonial, index) => (
          <Flex
            key={index}
            direction={{ base: "column", md: "row" }}
            bg={containerBgColor}
            p={6}
            rounded="lg"
            boxShadow="2xl"
            border="2px solid"
            borderColor={borderColor}
            align="center"
            gap={6}
          >
            {/* Text Section */}
            <Box flex="1" textAlign={{ base: "center", md: "left" }}>
              <Text fontSize="lg" fontStyle="italic" color={textColor}>
                "{testimonial.testimonial}"
              </Text>
              <Text mt={4} fontWeight="bold" color={textColor}>
                - {testimonial.name}
              </Text>
            </Box>
            {/* Image Section */}
            <Box flex="1" textAlign="center">
              <Image
                src={testimonial.image}
                alt={`Item made for ${testimonial.name}`}
                borderRadius="md"
                width="100%"
                height="auto"
                objectFit="cover"
              />
            </Box>
          </Flex>
        ))}
      </Stack>
    </Box>
  );
};

export default Testimonials;
