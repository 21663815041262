import React from "react";
import { Box, Container, Heading, Text, Image, Center, useColorModeValue } from "@chakra-ui/react";

const About = () => {
  const containerBgColor = useColorModeValue("rgba(255, 228, 245, 0.8)", "rgba(255, 182, 193, 0.8)");
  return (
    <Box
      py={10}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Center mb={8}>
        <Heading
          as="h1"
          size="2xl"
          color="pastel.lavender"  // Set the heading color to lavender
          fontFamily="heading"  // Use the Playfair Display font
          fontWeight="bold"  // Make the heading bold
        >
          About the Author
        </Heading>
      </Center>
      <Box
        maxW="container.md"
        borderRadius="lg"
        overflow="hidden"
        position="relative"
        bg={containerBgColor}
        color="pink.900"  // Set text color to deep pink
        boxShadow="2xl"  // Add a larger shadow for depth
        border="2px solid"
        borderColor="pink.200"  // Add a subtle pink border
      >
        {/* Decorative Frame */}
        <Box
          position="absolute"
          top={-4}
          right={-4}
          bottom={-4}
          left={-4}
          border="2px solid"
          borderColor="pastel.lavender"
          borderRadius="lg"
          pointerEvents="none"
          zIndex={-1}
          bg="transparent"
          borderStyle="dotted"  // Dotted border for the decorative frame
        />
        <Container
          maxW="container.md"
          p={8}
          borderRadius="lg"
          overflow="hidden"
          bgGradient="linear(to-r, pastel.lavender, pastel.pink, pastel.peach)"  // Gradient background
        >
          <Center mb={8}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Image
                src="./author.jpg"  // Replace with actual image URL
                alt="Author"
                borderRadius="full"
                boxSize="150px"
                objectFit="cover"
                mb={4}  // Add margin below the image
                border="4px solid"
                borderColor="pastel.lavender"  // Lavender border around the image
              />
              <Heading
                as="h2"
                size="lg"
                color="pink.900"  // Set the name color to deep pink
                fontFamily="heading"  // Use the Playfair Display font
                fontWeight="bold"  // Make the name bold
              >
                Nina Gia
              </Heading>
              <Text
                color="pink.700"  // Use a slightly lighter pink for the bio text
                textAlign="center"
                fontWeight="medium"  // Use medium font weight for readability
                fontSize="lg"
                fontFamily="body"  // Use Poppins for a clean look
              >
                <br/>
                By day, I’m a nurse preparing for the challenges of medical school, and by night, I dive into the world of spicy romantic comedies. My journey to becoming an author was as unexpected as it was fulfilling—what started as a love for books and a secret joy for writing has blossomed into a passion I can’t ignore.
                <br/>
                <br/>
                My experiences in the healthcare field provide endless inspiration for my writing, infusing my stories with a sense of empathy, urgency, and sometimes a bit of gallows humor that only someone in medicine can fully understand. 
                <br/>
                <br/>
                As I continue to chase my dreams in both medicine and writing, I’m excited to see where this unique blend of paths will take me. Whether I’m penning a chapter or studying anatomy, I’m always striving to balance heart and humor in everything I do.
                <br/>
                <br/>
                So, join me in my endeavors and dive into my stories, that will undoubtedly make your vagina-heart pulse.
                <br/>
                <br/>
                xoxo
              </Text>
              <Image
                src="signature.png"
                alt="signature"
                maxW="150px"
              />
            </Box>
          </Center>
        </Container>
      </Box>
    </Box>
  );
};

export default About;
