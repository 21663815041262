import React, { useState } from "react";
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { TfiEmail } from "react-icons/tfi";

const Contact = () => {
  const containerBgColor = useColorModeValue("rgba(255, 228, 245, 0.8)", "rgba(255, 182, 193, 0.8)");
  const buttonColor = useColorModeValue("#d791a6", "#d791a6");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, message } = formData;
    const mailtoLink = `mailto:ninagia.author@gmail.com?subject=Message from ${encodeURIComponent(name)}&body=${encodeURIComponent(message)}%0A%0AFrom: ${encodeURIComponent(name)}%0AEmail: ${encodeURIComponent(email)}`;

    // Open the mailto link
    window.location.href = mailtoLink;

    setSubmitted(true);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      justifyContent="center"
      p={6}
    >
      <Heading
        as="h1"
        size="2xl"
        mb={8}
        color="pastel.lavender"  // Heading color to pastel lavender
        fontFamily="heading"  // Use romantic font
        fontWeight="bold"
      >
        Contact Me!
      </Heading>

      <Box
        maxWidth="800px"
        bg={containerBgColor}
        p={6}
        borderRadius="md"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        boxShadow="xl"  // Larger shadow for emphasis
        border="2px solid"
        borderColor="pink.200"  // Soft pink border color
        position="relative"
        _before={{
          content: '""',
          position: "absolute",
          top: -4,
          right: -4,
          bottom: -4,
          left: -4,
          border: "2px dashed",
          borderColor: "pastel.peach",  // Dashed border with peach color
          borderRadius: "md",
          zIndex: -1,
        }}
      >
        <Box display="flex" alignItems="center" mb="10px">
          <TfiEmail size="24" color="#824786" style={{ marginRight: "8px" }} />
          <Text fontSize="md" color="#824786" fontWeight="bold">
            ninagia.author@gmail.com
          </Text>
        </Box>

        {submitted ? (
          <Box>
            <Heading as="h2" size="md" mb={4} color="#824786">
              Thank you for your message!
            </Heading>
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <FormControl mb={4}>
              <FormLabel htmlFor="name" color="pastel.pink">
                Name
              </FormLabel>
              <Input
                id="name"
                name="name"
                type="text"
                value={formData.name}
                onChange={handleChange}
                required
                borderColor="pastel.pink"
                focusBorderColor="pastel.pink"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="email" color="pastel.pink">
                Email
              </FormLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
                borderColor="pastel.pink"
                focusBorderColor="pastel.pink"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="message" color="pastel.pink">
                Message
              </FormLabel>
              <Textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                borderColor="pastel.pink"
                focusBorderColor="pastel.pink"
              />
            </FormControl>
            <Button
              mt={4}
              bgColor={buttonColor}  // Button color to pastel pink
              color="white"
              type="submit"
              fontWeight="bold"
              fontSize="lg"
              _hover={{ bg: "#824786" }}
              _focus={{ outline: "none", boxShadow: "outline" }}
            >
              Send
            </Button>
          </form>
        )}
      </Box>
    </Box>
  );
};

export default Contact;
