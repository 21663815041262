import { extendTheme } from "@chakra-ui/react";

// Import the font styles via @fontsource or from a web source
import "@fontsource/cinzel-decorative";
import "@fontsource/lora";
import "@fontsource/crimson-pro";
import "@fontsource/merriweather";

const theme = extendTheme({
  colors: {
    pink: {
      50: "#ffe4e6",
      100: "#ffccd4",
      200: "#ff99a3",
      300: "#ff6672",
      400: "#ff3342",
      500: "#ff0021",
      600: "#cc001a",
      700: "#990014",
      800: "#66000d",
      900: "#330007", 
    },
    pastel: {
      lavender: "#e6e6fa",
      blush: "#f9c6d0",
      mint: "#e0f5e9",
      peach: "#ffe5b4",
      sky: "#b3e5fc",
    },
    spice: {
      50: "#ffe5e5",
      100: "#ffb3b3",
      200: "#ff8080",
      300: "#ff4d4d",
      400: "#ff1a1a",
      500: "#e60000",
      600: "#b30000",
      700: "#800000",
      800: "#4d0000",
      900: "#1a0000",
    },
  },
  fonts: {
    body: "Merriweather, serif", // Softer, romantic serif font for body text
    heading: "Cinzel Decorative, serif", // Elegant serif font with a classic, romantic style for headings
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
    "2xl": "1.875rem",
    "3xl": "2.25rem",
    "4xl": "3rem",
    "5xl": "4rem",
    "6xl": "5rem",
  },
  styles: {
    global: {
      body: {
        bgImage: "url('/bg.png')", // Set the background image
        bgPosition: "center", // Center the background image
        bgRepeat: "no-repeat", // Prevent the image from repeating
        bgSize: "cover", // Make the image cover the whole page
        minHeight: "100vh",
        color: "pink.900",
        fontFamily: "body",
      },
      a: {
        color: "spice.500",
        _hover: {
          color: "spice.300",
        },
      },
      h1: {
        color: "pastel.lavender",
        fontFamily: "heading",
      },
      h2: {
        color: "pastel.mint",
        fontFamily: "heading",
      },
      h3: {
        color: "pastel.peach",
        fontFamily: "heading",
      },
      button: {
        bg: "pink.500",
        color: "white",
        _hover: {
          bg: "spice.400",
        },
      },
    },
  },
});

export default theme;
