import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  Image,
  Grid,
  GridItem,
  Button,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
} from "@chakra-ui/react";

const premadeCovers = [
  {
    id: 1,
    title: "Premade Cover 1",
    price: "25€",
    imageUrl: "/cover1.jpg",
  },
  {
    id: 2,
    title: "Premade Cover 2",
    price: "25€",
    imageUrl: "/cover2.jpg",
  },
  // Add more covers here
];

const Services = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(null);
  const containerBgColor = useColorModeValue("rgba(255, 228, 245, 0.8)", "rgba(255, 182, 193, 0.8)");

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    onOpen();
  };

  const handleRequestClick = (title) => {
    window.location.href = `mailto:ninagia.author@gmail.com?subject=Request for ${title}&body=Hello, I would like to request this cover. Can't wait for your response! Thank you!`;
  };

  const buttonColor = useColorModeValue("#d791a6", "#d791a6");
  return (
    <Flex p={6} flexDirection="column" justifyContent="center" alignItems="center">
      {/* Introduction Text */}
      <Heading as="h2" size="xl" mb={4} color="pastel.pink" >
        Author Services
      </Heading>
      <Box
        maxW="container.md"
        mb={4}
        p={8}
        borderRadius="lg"
        overflow="hidden"
        position="relative"
        bg={containerBgColor}
        color="pink.900"  // Set text color to deep pink
        boxShadow="2xl"  // Add a larger shadow for depth
        border="2px solid"
        borderColor="pink.200"  // Add a subtle pink border
      >
        <Text fontSize="lg" mb={8} color="pink.900">
        I provide a range of author services tailored to help you succeed. From crafting compelling blurbs and descriptions to offering premade book covers that can be personalized to fit your vision, I ensure your book stands out. I also create custom book graphics and Instagram visuals to enhance your marketing efforts. Additionally, I accept commissions for unique, tailored projects. Whether you're preparing to publish or promoting your work, I'm here to support you every step of the way.
        </Text>

        <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={6}>
        {premadeCovers.map((cover) => (
          <GridItem key={cover.id}>
            <Box
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              bg={containerBgColor}
              textAlign="center"
              p={4}
              boxShadow="md"
            >
              <Image
                src={cover.imageUrl}
                alt={cover.title}
                borderRadius="md"
                objectFit="cover"
                cursor="pointer"
                onClick={() => handleImageClick(cover.imageUrl)}
              />
              <Text mt={2} fontSize="md" color="pink.700">
                {cover.price}
              </Text>
              <Button
                mt={4}
                bgColor={buttonColor}
                type="submit"
                fontWeight="bold"
                fontSize="lg"
                color="white"
                borderRadius="md"
                _hover={{ bg: "#824786" }}
                _focus={{ outline: "none", boxShadow: "outline" }}
                onClick={() => handleRequestClick(cover.title)}
              >
                Request This
              </Button>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Box>

      {/* Image Zoom Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Image src={selectedImage} alt="Selected Cover" w="100%" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Services;
