import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Flex,
  Image,
  Heading,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";

const Navbar = () => {
  const textColor = useColorModeValue("pink.900", "white");

  return (
    <Box  p={4}>
      <Flex
        maxW="container.xl"
        mx="auto"
        flexDir="column"
        align="center"
        justifyContent="center"
      >
        <Heading
          as="h2"
          size="lg"
          color={textColor}
          fontFamily="heading"
        >
          <Link as={RouterLink} to="/">
            <Image
              src="/logo.png"
              alt="Nina's Logo"
              height="80px"
              filter={useColorModeValue("none", "invert(1)")}
              mx="auto" // Centers the logo image
            />
          </Link>
        </Heading>
        <Flex
          align="center"
          overflow="auto"
          mt={2} // Adds some margin-top to separate the links from the logo
          flexWrap="wrap"
          justifyContent="center"
        >
          <NavLink to="/about">About</NavLink>
          <NavLink to="/books">Books</NavLink>
          {/* <NavLink to="/characters">Characters</NavLink> */}
          {/* <NavLink to="/reviews">Reviews</NavLink> */}
          {/* <NavLink to="/art">Art</NavLink>
          <NavLink to="/readings">Readings</NavLink> */}
          <NavLink to="/contact">Contact</NavLink>
          <NavLink to="/shop">Shop</NavLink>
          <NavLink to="/services">Author Services</NavLink>
          <NavLink to="/testimonials">Testimonials</NavLink>
          <NavLink to="/newsletter">Newsletter</NavLink>
        </Flex>
      </Flex>
    </Box>
  );
};

const NavLink = ({ to, children }) => {
  return (
    <Link
      as={RouterLink}
      to={to}
      p={2}
      mx={2}
      fontSize="lg"
      fontWeight="bold"
      fontFamily="body"
      color="#824786"
      _hover={{ textDecoration: "none", color: "lavender" }}
    >
      {children}
    </Link>
  );
};

export default Navbar;
