import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
  useBreakpointValue,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";

const booksData = [
  {
    id: 1,
    title: "Love or Tequila in Unexpected Places",
    series: "Love or Alcohol Series #1",
    release: "October 11th 2024",
    cover: "/book1cover.png",
    blurb: ( 
      <>"You're pressing your luck," Allison said, crossing her arms and popping her hip. Angelo grew hot. He was always hot for that woman. And so he slipped up and said- "Would you press it for me, sweet girl?"
      <br/>
      Allison Pink Lockwood has spent her life trying to meet everyone's high expectations-especially those of her powerful father. So when she decides to throw caution to the wind and indulge in one night of fun, she doesn't expect to wake up with more than just a hangover. Thanks to a few too many shots of tequila, she finds herself unexpectedly pregnant and tied to her father's biggest rival. Fuck.
      <br/>
      Angelo Taylor is a man who thrives on control. As the CEO of a multinational corporation, he has no time for distractions or for falling for the fiery woman he barely knows. Except that he knew her very... closely. When a one-night stand leads to a surprise pregnancy, Angelo discovers that letting loose might be exactly what he needs.
      <br/>
      As they navigate the chaos of their situation, both Allison and Angelo find themselves unexpectedly drawn to one another.S Against cars, on kitchen counters and in showers... With the Impending arrival of their baby, will they learn to embrace the unpredictable-and discover love in the most unlikely of places?
      <br/>
      Join Allison and Angelo in this spicy, hilarious, heartfelt rom-com where tequila might just be the catalyst for something beautiful.
      </>
    ),
    link: "https://books2read.com/u/3LJZ1X",
    goodreads: "https://www.goodreads.com/book/show/215992664-love-or-tequila-in-unexpected-places"
  },
];

const Books = () => {
  const cardDirection = useBreakpointValue({ base: "column", sm: "row" });
  const containerBgColor = useColorModeValue("rgba(255, 228, 245, 0.8)", "rgba(255, 182, 193, 0.8)");

  return (
    <Box p={4} display="flex" flexDirection="column" alignItems="center">
      <Heading
        as="h1"
        size="2xl"
        mb={8}
        fontFamily="heading"  // Use Playfair Display font
        fontWeight="bold"
      >
        Books
      </Heading>
      <Box maxW="100%">
        {booksData.map((book) => (
          <Flex
            key={book.id}
            mb={6}
            p={4}
            maxW="container.lg"
            borderWidth="2px solid"
            borderRadius="lg"
            overflow="hidden"
            bgColor={containerBgColor}
            color="pink.900"  // Deep pink text color
            alignItems="center"
            flexDirection={cardDirection}
            boxShadow="md"  // Add subtle shadow for depth
            borderColor="pink.200" 
          >
            <Image
              src={book.cover}
              alt={book.title}
              borderRadius="md"
              boxSize="200px"
              objectFit="cover"
              mr={4}  // Margin to the right for spacing in row layout
            />
            <Box p="4">
              <Heading
                as="h2"
                size="lg"
                mb={2}
                color="pink.800"  // Set title color to a darker pink
                fontFamily="heading"  // Use Playfair Display font
                fontWeight="bold"
              >
                {book.title}
              </Heading>
              <Text
                fontSize="md"
                fontWeight="medium"
                mb={4}
                color="pink.800"  // Slightly lighter pink for the blurb
              >
                {book.series}
              </Text>
              <Text
                fontSize="md"
                fontWeight="medium"
                mb={4}
                color="pink.800"  // Slightly lighter pink for the blurb
              >
                {book.release}
              </Text>
              <Text
                fontSize="md"
                fontWeight="medium"
                mb={4}
                color="pink.700"  // Slightly lighter pink for the blurb
              >
                {book.blurb}
              </Text>
              <Flex gap={10}>
                 <Link
                href={book.link}
                isExternal
                color="#824786"  // Use pink color for the link
                fontWeight="bold"
                _hover={{color: "#824786", textDecor: "underline"}}  // Underline on hover
              >
                <Text fontSize="md">
                  Preorder Now
                </Text>
              </Link>
              <Link
                href={book.goodreads}
                isExternal
                color="#824786"  // Use pink color for the link
                fontWeight="bold"
                _hover={{color: "#824786", textDecor: "underline"}}  // Underline on hover
              >
                <Text fontSize="md">
                  Add to Goodreads
                </Text>
              </Link>
              </Flex>
             
            </Box>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default Books;
